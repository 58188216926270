import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { getFormattedDate } from "../../../hooks/getFormatedDate";
import {FilterSearch} from "../../../types/types";
import {useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {ReducerStateTypes} from "../../../types/redux/ReducerStateTypes";
import {setActiveFilters} from "../../../actions";
import nl from "date-fns/locale/nl";
import UrlUtil from "../../../utils/UrlUtil";

interface DatePickerProps {
  setDateDropdown: React.Dispatch<React.SetStateAction<boolean>>;
}

const DatePicker: React.FC<DatePickerProps> = ({
  setDateDropdown,
}) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedFilters: FilterSearch[] = useSelector((state: ReducerStateTypes) => state.filter.selectedFilters);
  const dispatch = useDispatch();

  const handleSelect = (ranges: any) => {
    let activeFilters: FilterSearch[] = [...selectedFilters.filter(f => (f.filterType !== "departure" && (f.filterSubCategory !== "flexible")))];
    let urlFilters: FilterSearch[] = UrlUtil.parseUrlParamsToFilters(searchParams.get("filter"));
    const formattedDate: string = getFormattedDate(new Date(ranges?.range1?.startDate));
    const index: number = selectedFilters.findIndex(f => f.filterType === "departure");
    const filter: FilterSearch = {
      filterCategory: "vacation",
      filterSubCategory: "date",
      filterType: "departure",
      value: formattedDate,
      displayValue: formattedDate
    };
    if (index > -1) {
      activeFilters[index] = filter;
    } else {
      activeFilters.push(filter);
    }
    setSearchParams(params => {
      params.set("filter", UrlUtil.parseFiltersToUrlParams([...urlFilters.filter(f => (f.filterType !== "departure" && (f.filterSubCategory !== "flexible"))), filter]));
        return params;
    });
    setSelectedDate(ranges?.range1?.startDate || ranges?.range1?.endDate);
    setTimeout(() => {
      setDateDropdown(false);
    }, 20);
    dispatch(setActiveFilters([...activeFilters]));
  };

  return (
    <DateRangePicker
      rangeColors={["#e9effd"]}
      ranges={[{ startDate: selectedDate, endDate: selectedDate }]}
      onChange={handleSelect}
      className="selectedDateRange"
      minDate={new Date()}
      locale={nl}
      fixedWidth={"100%"}
    />
  );
};

export default DatePicker;
