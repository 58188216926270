import React, {useRef} from "react";
import SelectCity from "./SelectCity/SelectCity";
import {CityFilterValue, FilterSearch, FilterValue, LoadingState} from "../../../../types/types";
import "./SelectRegion.scss";
import {useSelector} from "react-redux";
import {ReducerStateTypes} from "../../../../types/redux/ReducerStateTypes";
import Skeleton from "react-loading-skeleton";

interface SelectRegionProps {
    show: boolean;
    country: FilterSearch | undefined;
    setRegion: (value: string | undefined) => void;
    setCity: (value: string | undefined) => void;
    regions: Array<FilterValue>;
    cities: Array<CityFilterValue>;
    isSidebar?: boolean;
}

const SelectRegion: React.FC<SelectRegionProps> = ({
                                                       show,
                                                       country,
                                                       setRegion,
                                                       setCity,
                                                       regions,
                                                       cities,
                                                       isSidebar
                                                   }) => {

    const isLoading: LoadingState = useSelector((state: ReducerStateTypes) => state.loading.isLoading);
    const scrollableDivRef = useRef<HTMLDivElement>(null);

    if (!show) {
        return null;
    }

    if (country.value === "allCountries") {
        return (
            <div className="region-wrapper">
                <h5>{country.displayValue} ({country.count})</h5>
                <hr/>
                <b><i>Selecteer een land om op plaats te filteren</i></b>
            </div>
        )
    }

    return (
        <div ref={scrollableDivRef} className="region-wrapper">
            <h5>{country.displayValue} ({country.count})</h5>
            <div className="select-btn-wrapper">
                {
                    isLoading.filter ? Array.from({length: 5}).map((_, index) => (
                        <>
                            <div key={index} className="select-btn">
                                <input
                                    type="radio"
                                    disabled
                                />
                                <Skeleton height={"12px"} width={"160px"}/>
                            </div>
                            <div className={"select-btn-wrapper-list"}></div>
                        </>
                    )) : (
                        regions?.map((region, index) => {
                            return <SelectCity
                                key={index}
                                region={region}
                                setCity={setCity}
                                setRegion={setRegion}
                                cities={cities}
                                scrollableDivRef={scrollableDivRef}
                                isSidebar={isSidebar}
                            />
                        })
                    )
                }
            </div>
        </div>

    );
}

export default SelectRegion;