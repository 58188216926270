export enum Provider {
    TUI = "tui",
    SUNWEB = "sunweb",
    DE_JONG_INTRA = "de jong intra",
    ELIZA_WAS_HERE = "eliza was here",
    D_REIZEN = "d-reizen",
    THOMAS_COOK = "thomas cook",
    CORENDON = "corendon",
    LAVIDA_TRAVEL = "lavida travel",
    PRIJS_VRIJ = "prijsvrij",
    ITALIE_PLANNER = "italie planner",
    SUN_TIP = "suntip",
}
