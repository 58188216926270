import React from "react";
import {FilterSearch} from "../../../../../types/types";
import {useDispatch, useSelector} from "react-redux";
import {ReducerStateTypes} from "../../../../../types/redux/ReducerStateTypes";
import {setActiveFilters} from "../../../../../actions";
import "./SelectCity.scss";
import FilterUtil from "../../../../../utils/FilterUtil";
import {useSearchParams} from "react-router-dom";
import UrlUtil from "../../../../../utils/UrlUtil";

interface SelectCityProps {
    region: { displayValue: string, value: string, count: number };
    setCity: (value: string | undefined) => void;
    setRegion: (value: string | undefined) => void;
    cities: Array<{ displayValue: string, value: string, region: string, count: number }>;
    scrollableDivRef: React.RefObject<HTMLDivElement>;
    isSidebar?: boolean;
}

const SelectCity: React.FC<SelectCityProps> = ({
                                                   region,
                                                   setCity,
                                                   setRegion,
                                                   cities,
                                                   scrollableDivRef,
                                                    isSidebar
                                               }) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const selectedFilters: FilterSearch[] = useSelector((state: ReducerStateTypes) => state.filter.selectedFilters);
    const dispatch = useDispatch();

    const handleCity = (e: React.ChangeEvent<HTMLInputElement>, city: {
        displayValue: string,
        value: string,
        count: number
    }) => {
        const index: number = selectedFilters.findIndex(f => f.value === city.value && f.filterType === "city");
        let activeFilters: FilterSearch[] = [...selectedFilters];
        let urlFilters: FilterSearch[] = UrlUtil.parseUrlParamsToFilters(searchParams.get("filter"));
        const urlIndex: number = urlFilters.findIndex(f => f.value === city.value && f.filterType === "city");

        if (e.target.checked && index === -1) {
            activeFilters = [...selectedFilters, {
                filterCategory: "accommodation",
                filterType: "city",
                value: city.value,
                displayValue: city.displayValue
            }];

            setCity(city.displayValue);

            if (city.value === "allCities") {
                activeFilters = activeFilters.filter(f => (f.filterType !== "city" || f.value === "allCities"));
                urlFilters = urlFilters.filter(f => (f.filterType !== "city" || f.value === "allCities"));
            } else {
                activeFilters = activeFilters.filter(f => f.value !== "allCities");
                urlFilters = urlFilters.filter(f => f.value !== "allCities");
            }
        } else {
            activeFilters.splice(index, 1);
            urlFilters.splice(urlIndex, 1);
            setCity(undefined);
        }

        setSearchParams(params => {
            params.set("filter", UrlUtil.parseFiltersToUrlParams(FilterUtil.removeDuplicates([...activeFilters, ...urlFilters])));
            return params;
        });
        dispatch(setActiveFilters([...activeFilters]));

        if (scrollableDivRef.current) {
            scrollableDivRef.current.scrollTop = 0;
        }
    }

    const handleRegion = (e: React.ChangeEvent<HTMLInputElement>, region: {
        displayValue: string,
        value: string,
        count: number
    }) => {
        const index: number = selectedFilters.findIndex(f => f.value === region.value && f.filterType === "region");
        let activeFilters: FilterSearch[] = [...selectedFilters];
        let urlFilters: FilterSearch[] = UrlUtil.parseUrlParamsToFilters(searchParams.get("filter"));
        const urlIndex: number = urlFilters.findIndex(f => f.value === region.value && f.filterType === "region");

        if (e.target.checked && index === -1) {
            activeFilters = [...selectedFilters, {
                filterCategory: "accommodation",
                filterType: "region",
                value: region.value,
                displayValue: region.displayValue
            }];

            setRegion(region.displayValue);

            if (region.value === "allRegions") {
                activeFilters = activeFilters.filter(f => f.filterType !== "city" && (f.filterType !== "region" || f.value === "allRegions"));
                urlFilters = urlFilters.filter(f => f.filterType !== "city" && (f.filterType !== "region" || f.value === "allRegions"));
            } else {
                activeFilters = activeFilters.filter(f => f.value !== "allRegions");
                urlFilters = urlFilters.filter(f => f.value !== "allRegions");
            }
        } else {
            const cityIndex: number = selectedFilters.findIndex(f => f.filterType === "city");
            if (cityIndex > -1) {
                activeFilters.splice(cityIndex, 1);
            }
            activeFilters.splice(index, 1);
            urlFilters.splice(urlIndex, 1);
            setRegion(undefined);
        }
        setSearchParams(params => {
            params.set("filter", UrlUtil.parseFiltersToUrlParams(FilterUtil.removeDuplicates([...activeFilters, ...urlFilters])));
            return params;
        });
        dispatch(setActiveFilters([...activeFilters]));

        if (scrollableDivRef.current) {
            scrollableDivRef.current.scrollTop = 0;
        }
    }

    const filterExists = (callback: (f: FilterSearch) => boolean, condition: "or" | "and") => {
        if (condition === "or") {
            return selectedFilters.findIndex(callback) > -1 || UrlUtil.parseUrlParamsToFilters(searchParams.get("filter")).findIndex(callback) > -1;
        }
        if (condition === "and") {
            return selectedFilters.findIndex(callback) > -1 && UrlUtil.parseUrlParamsToFilters(searchParams.get("filter")).findIndex(callback) > -1;
        }
    }

    return (
        <>
            <button className={`select-btn ${isSidebar && 'sidebar'}`}>
                <input
                    type="radio"
                    name="region"
                    id={region.value}
                    onChange={(e) => {
                        handleRegion(e, region);
                    }}
                    checked={filterExists(f => f.value === region.value, "or") || (region.value === "allRegions" && selectedFilters.findIndex(f => f.filterType === "region") === -1)}
                />{" "}
                <label htmlFor={region.value}>{`${region.displayValue} (${region.count})`}</label>
            </button>
            {filterExists(f => f.filterType === "region", "or") ?
                <div className={"select-btn-wrapper-list"}>
                    {
                        region.value === "allRegions" || selectedFilters.find(f => f.filterType === "region")?.value !== region.value ? null : cities.map((city, index) => {
                            return (
                                <button key={index} className={`select-btn ${isSidebar && 'sidebar'}`}>
                                    <input
                                        type="radio"
                                        name="city"
                                        id={city.value}
                                        checked={filterExists(f => f.value === city.value, "or") || (city.value === "allCities" && selectedFilters.findIndex(f => f.filterType === "city") === -1)}
                                        onChange={(e) => handleCity(e, city)}
                                    />
                                    <label htmlFor={city.value}>{`${city.displayValue} (${city.count})`}</label>
                                </button>
                            )
                        })}
                </div>
                : null
            }
        </>
    )
}

export default SelectCity;

