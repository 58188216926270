import React, {useEffect, useRef, useState} from "react";
import "./SelectLocation.scss";
import "../SelectDestination/SelectDestination.scss";
import {FilterSearch, FilterValue} from "../../../types/types";
import {useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {ReducerStateTypes} from "../../../types/redux/ReducerStateTypes";
import {setActiveFilters} from "../../../actions";
import UrlUtil from "../../../utils/UrlUtil";
import * as url from "node:url";
import FilterUtil from "../../../utils/FilterUtil";

interface SelectLocationProps {
    showing: string;
    vacationTypes: Array<FilterValue>;
    isSidebar?: boolean;
}

const SelectLocation: React.FC<SelectLocationProps> = ({
                                                           showing,
                                                           vacationTypes,
                                                           isSidebar
                                                       }) => {
    const [isOpenAddLocationDropDown, setIsopenAddLocationDropdown] =
        useState(false);
    const [isOpenDestination, setIsOpenDestnation] = useState(false);
    const [currentVacationType, setVacationType] = useState<FilterSearch | undefined>(undefined);
    const [searchParams, setSearchParams] = useSearchParams();
    const selectedFilters: FilterSearch[] = useSelector((state: ReducerStateTypes) => state.filter.selectedFilters);
    const dispatch = useDispatch();

    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const onOutsideClick = () => {
        setIsopenAddLocationDropdown(false);
        setIsOpenDestnation(false);
    };
    useEffect(() => {
        const selectedType = selectedFilters.find(filter => (filter.filterType === "type" || filter.filterType === "all_types") && filter.filterCategory === "vacation");
        if (selectedType === undefined) {
            const urlFilters: FilterSearch[] = UrlUtil.parseUrlParamsToFilters(searchParams.get("filter"));
            const typeFilter: FilterSearch = urlFilters.find(filter => (filter.filterType === "type" || filter.filterType === "all_types") && filter.filterCategory === "vacation");
            setVacationType(typeFilter);
        } else {
            setVacationType(selectedType);
        }
    }, [searchParams, selectedFilters, setVacationType]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target as Node)
            ) {
                onOutsideClick();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onOutsideClick]);

    const handleVakantietype = (value: string, displayValue) => {
        setIsopenAddLocationDropdown(false);

        let urlFilters = UrlUtil.parseUrlParamsToFilters(searchParams.get("filter"));
        let activeFilters = [...selectedFilters, ...urlFilters];
        const index: number = activeFilters.findIndex(filter => (filter.filterType === "type" || filter.filterType === "all_types") && filter.filterCategory === "vacation");
        const filter: FilterSearch = {
            filterCategory: "vacation",
            filterType: "type",
            value: value,
            displayValue: displayValue
        };
        if (index > -1) {
            activeFilters.splice(index);
        }
        activeFilters.push(filter);

        setSearchParams(params => {
            params.set("filter", UrlUtil.parseFiltersToUrlParams(FilterUtil.removeDuplicates([...activeFilters])));
            return params;
        })
        dispatch(setActiveFilters([...activeFilters]));
    };

    const removeVakantietype = () => {
        const urlFilters = UrlUtil.parseUrlParamsToFilters(searchParams.get("filter"));
        const activeFilters = [...selectedFilters, ...urlFilters].filter(filter => filter.filterType !== "type" || filter.filterCategory !== "vacation");
        const filters = FilterUtil.removeDuplicates(activeFilters);

        setSearchParams(params => {
            params.set("filter", UrlUtil.parseFiltersToUrlParams(filters));
            return params;
        });
        dispatch(setActiveFilters([...filters]));
    }

    return (
        <div id="select-location" ref={wrapperRef}>
            <h5
                onClick={() => setIsopenAddLocationDropdown(!isOpenAddLocationDropDown)}
            >
                {currentVacationType?.displayValue === undefined
                    ? showing
                    : currentVacationType.displayValue}
            </h5>

            {isOpenAddLocationDropDown && (
                <div className={`select-destination-contents ${isSidebar && 'sidebar'}`}>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <h3 style={{wordBreak: "break-word", width: "55%"}}>Selecteer uw vakantie type</h3>
                        {
                            currentVacationType ?
                                <a
                                    onClick={removeVakantietype}
                                    style={{cursor: "pointer", height: "min-content"}}
                                >
                                    Verwijder keuze
                                </a> : null
                        }
                    </div>

                    <div className={`destination-grid-contents ${isSidebar && 'sidebar'}`}>
                        {vacationTypes.map((item, index) => (
                            <div
                                key={index}
                                className="single-item"
                                onClick={() => handleVakantietype(item.value, item.displayValue)}
                            >
                                {/*<img src={item.img} alt="" />*/}
                                <div className="text-content">
                                    {item.displayValue}&nbsp;({item.count})
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default SelectLocation;
