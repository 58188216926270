import {Action} from "redux";

export const ADD_PARTICIPANT_ADULT: string = "ADD_PARTICIPANT_ADULT";
export const ADD_PARTICIPANT_CHILD: string = "ADD_PARTICIPANT_CHILD";
export const ADD_PARTICIPANT_BABY: string = "ADD_PARTICIPANT_BABY";
export const REMOVE_PARTICIPANT_ADULT: string = "REMOVE_PARTICIPANT_ADULT";
export const REMOVE_PARTICIPANT_CHILD: string = "REMOVE_PARTICIPANT_CHILD";
export const REMOVE_PARTICIPANT_BABY: string = "REMOVE_PARTICIPANT_BABY";
export const SET_PARTICIPANTS: string = "SET_PARTICIPANTS";

export const addParticipantAdult = (): Action => ({
    type: ADD_PARTICIPANT_ADULT,
});

export const removeParticipantAdult = (): Action => ({
    type: REMOVE_PARTICIPANT_ADULT,
});

export const addParticipantChild = (): Action => ({
    type: ADD_PARTICIPANT_CHILD,
});

export const removeParticipantChild = (): Action => ({
    type: REMOVE_PARTICIPANT_CHILD,
});

export const addParticipantBaby = (): Action => ({
    type: ADD_PARTICIPANT_BABY,
});

export const removeParticipantBaby = (): Action => ({
    type: REMOVE_PARTICIPANT_BABY,
});

export const setParticipants = (value: {
    adults: number,
    children: number,
    babies: number
}) => ({
    type: SET_PARTICIPANTS,
    payload: value
});