import React, {useEffect, useRef, useState} from "react";
import "./SelectDestination.scss";
import "../EnterDate/EnterDate.scss"
import {CityFilterValue, FilterSearch, FilterValue} from "../../../types/types";
import {useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {ReducerStateTypes} from "../../../types/redux/ReducerStateTypes";
import {setActiveFilters} from "../../../actions";
import SelectRegion from "./SelectRegion/SelectRegion";
import UrlUtil from "../../../utils/UrlUtil";
import FilterUtil from "../../../utils/FilterUtil";

interface SelectDestinationProps {
    showing: string;
    countries: Array<FilterValue>;
    cities: Array<CityFilterValue>;
    regions: Array<FilterValue>;
    isSidebar?: boolean;
}

const SelectDestination: React.FC<SelectDestinationProps> = ({
                                                                 showing,
                                                                 countries,
                                                                 cities,
                                                                 regions,
                                                                 isSidebar
                                                             }) => {
    const [isOpenDestination, setIsOpenDestnation] = useState(false);
    const [popularCountries, setPopularCountries] = useState([]);
    const [showAllCountries, setShowAllCountries] = useState(false);
    const [currentCountry, setCurrentCountry] = useState<FilterSearch | undefined>(undefined);
    const [currentRegion, setCurrentRegion] = useState<string | undefined>(undefined);
    const [currentCity, setCurrentCity] = useState<string | undefined>(undefined);
    const [searchParams, setSearchParams] = useSearchParams();
    const selectedFilters: FilterSearch[] = useSelector((state: ReducerStateTypes) => state.filter.selectedFilters);
    const dispatch = useDispatch();

    const wrapperRef = useRef<HTMLDivElement | null>(null);

    const onOutsideClick = () => {
        setIsOpenDestnation(false);
    };

    useEffect(() => {
        const popularCountries = [...countries].sort((a, b) => b.count - a.count);
        popularCountries.splice(10);
        setPopularCountries(popularCountries);
    }, [countries]);

    useEffect(() => {
        const selectedCountry = selectedFilters.find(filter => filter.filterType === "country" || filter.filterType === "all_countries");
        if (selectedCountry === undefined) {
            const urlFilters: FilterSearch[] = UrlUtil.parseUrlParamsToFilters(searchParams.get("filter"));
            const countryFilter: FilterSearch = urlFilters.find(filter => filter.filterType === "country" || filter.filterType === "all_countries");
            setCurrentCountry(countryFilter);
            setCurrentCity(
                urlFilters.find(filter => filter.filterType === "city")?.displayValue
            );
            setCurrentRegion(
                urlFilters.find(filter => filter.filterType === "region")?.displayValue
            );
        } else {
            setCurrentCountry(selectedCountry);
            setCurrentRegion(
                selectedFilters.find(filter => filter.filterType === "region")?.displayValue
            );
            setCurrentCity(
                selectedFilters.find(filter => filter.filterType === "city")?.displayValue
            );
        }
    }, [searchParams, selectedFilters, setCurrentCountry]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target as Node)
            ) {
                onOutsideClick();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onOutsideClick]);

    const handleDestination = (ds: { displayValue: string; value: string; count: number; }) => {
        const urlFilters: FilterSearch[] = UrlUtil.parseUrlParamsToFilters(searchParams.get("filter"));
        const activeFilters: FilterSearch[] = [...selectedFilters, ...urlFilters];
        const index: number = activeFilters.findIndex(filter => filter.filterType === "country" || filter.filterType === "all_countries");
        const filter: FilterSearch = {
            filterCategory: "accommodation",
            filterType: "country",
            value: ds.value,
            displayValue: ds.displayValue,
            count: ds.count
        };
        if (index > -1) {
            activeFilters.splice(index);
        }

        activeFilters.push(filter);

        setSearchParams(params => {
            params.set("filter", UrlUtil.parseFiltersToUrlParams(FilterUtil.removeDuplicates([...activeFilters])));
            return params;
        });
        dispatch(setActiveFilters([...activeFilters]));
    };

    const renderDestination = () => {
        if (currentCountry?.displayValue === undefined) {
            return showing;
        }
        return (
            <>
                {currentCountry.value === "allCountries" ? "🌎" : <img style={{margin: "0px 4px"}}
                                                                      src={`https://flagcdn.com/24x18/${currentCountry.value.toLowerCase()}.png`}/>}
                {currentCity ? currentCity : currentRegion ? currentRegion : currentCountry.displayValue}
            </>
        )
    }

    const renderCountryList = (item: { displayValue: string, value: string, count: number }, key: number) => {
        return (
            <div
                key={key}
                className="single-item"
                onClick={() => handleDestination(item)}
            >
                {item.value === "allCountries" ? "🌎 " :
                    <img src={`https://flagcdn.com/24x18/${item.value.toLowerCase()}.png`}/>}
                {item.displayValue}({item.count})
            </div>
        )
    }

    const toggleSeeAll = () => {
        setShowAllCountries(!showAllCountries);
        setCurrentCountry(undefined);
        setCurrentRegion(undefined);
        setCurrentCity(undefined);
        const urlFilters: FilterSearch[] = UrlUtil.parseUrlParamsToFilters(searchParams.get("filter"));
        const newFilters = [...selectedFilters, ...urlFilters].filter(filter => filter.filterType !== "country" && filter.filterType !== "all_countries" && filter.filterType !== "region" && filter.filterType !== "city");
        const filters = FilterUtil.removeDuplicates(newFilters);
        setSearchParams(params => {
            params.set("filter", UrlUtil.parseFiltersToUrlParams(FilterUtil.removeDuplicates(filters)));
            return params;
        });
        dispatch(setActiveFilters([...filters]));
    }

    const removeSelection = () => {
        setCurrentCountry(undefined);
        setCurrentRegion(undefined);
        setCurrentCity(undefined);
        const urlFilters: FilterSearch[] = UrlUtil.parseUrlParamsToFilters(searchParams.get("filter"));
        const newFilters = [...selectedFilters, ...urlFilters].filter(filter => filter.filterType !== "country" && filter.filterType !== "all_countries" && filter.filterType !== "region" && filter.filterType !== "city");
        const filters = FilterUtil.removeDuplicates(newFilters);
        setSearchParams(params => {
            params.set("filter", UrlUtil.parseFiltersToUrlParams(FilterUtil.removeDuplicates(filters)));
            return params;
        });
        dispatch(setActiveFilters([...filters]));
    }

    return (
        <div id="select-destination" ref={wrapperRef}>
            <h5 onClick={() => setIsOpenDestnation(!isOpenDestination)}>
                {renderDestination()}
            </h5>

            {isOpenDestination && (
                <div className={`select-destination-contents ${isSidebar && 'sidebar'}`}>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <h3>{currentCountry ?
                            "Gekozen Bestemming" :
                            showAllCountries ?
                                "Alle Bestemmingen" :
                                "Populaire Bestemmingen"
                        }</h3>
                    </div>
                    <div style={{display: "flex"}}>
                        <div className={`destination-grid-contents ${isSidebar && 'sidebar'}`}>
                            {currentCountry !== undefined ? null : showAllCountries ? countries?.map(renderCountryList) : popularCountries?.map(renderCountryList)}
                        </div>
                        <SelectRegion
                            show={currentCountry !== undefined}
                            country={currentCountry}
                            setRegion={setCurrentRegion}
                            setCity={setCurrentCity}
                            cities={cities}
                            regions={regions}
                            isSidebar={isSidebar}
                        />
                    </div>
                    <div className="bottom-btn-group">
                        <p style={{color: "blue", textDecoration: "underline", cursor: "pointer"}}
                           onClick={toggleSeeAll}>
                            {currentCountry ? null : showAllCountries ? "Zie Populaire Bestemmingen" : "Zie Alle Bestemmingen"}
                        </p>
                        {
                            currentCountry &&
                            <button
                                className="link-btn"
                                onClick={removeSelection}>Verwijder keuze</button>
                        }

                        {
                            !isSidebar && <button
                                className="opslaan-btn"
                                onClick={() => setIsOpenDestnation(!isOpenDestination)}
                            >
                                Opslaan
                            </button>
                        }
                    </div>
                </div>
            )}
        </div>
    );
};

export default SelectDestination;
